<template>
   <div class="top-nav">
      <p class="promotion-text text-white"><b>Wallet</b></p>
   </div>
   <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
     <div class="van-toast__text">success</div>
   </div>
   <div data-v-0dabd3fc="" class="wallet-container" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
      <div data-v-0dabd3fc="" class="wallet-container-header">
         <div data-v-0dabd3fc="" class="wallet-container-header-belly">
            <svg data-v-0dabd3fc="" class="svg-icon icon-wallet1">
               <use xlink:href="#icon-wallet1"></use>
            </svg>
            <div data-v-0dabd3fc="">₹{{ userdetails.userBalance }}</div>
            <span data-v-0dabd3fc="">Total balance</span>
            <div data-v-0dabd3fc="">
               <div data-v-0dabd3fc="">
                  <p data-v-0dabd3fc="" class="total" v-if="userdetails.userWidthdraw!==null">{{ userdetails.userWidthdraw }}</p>
                  <p data-v-0dabd3fc="" class="total" v-else>0</p>
                  <p data-v-0dabd3fc="" style="font-size:12px;">Total amount</p>
               </div>
               <div data-v-0dabd3fc="">
                  <p data-v-0dabd3fc="" class="total" v-if="userdetails.userDeposit !== null">{{ userdetails.userDeposit }}</p>
                  <p data-v-0dabd3fc="" class="total" v-else>0</p>
                  <p data-v-0dabd3fc="" style="font-size:12px;">Total deposit amount</p>
               </div>
            </div>
         </div>
      </div>
      <div data-v-0dabd3fc="" class="wallet-container-content">
         <div data-v-0dabd3fc="" class="container">
            <div data-v-0dabd3fc="" class="progressBars">
               <div data-v-0dabd3fc="" class="progressBarsL">
                  <div class="container progress-seting">
                     <div class="row">
                        <div class="col-md-3 col-sm-6">
                           <div class="progress blue">
                              <span class="progress-left">
                              <span class="progress-bar"></span>
                              </span>
                              <span class="progress-right">
                              <span class="progress-bar"></span>
                              </span>
                              <div class="progress-value">100%</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <h3 data-v-0dabd3fc="">₹{{ userdetails.userBalance }}</h3>
                  <span data-v-0dabd3fc="">Main wallet</span>
               </div>
               <div data-v-0dabd3fc="" class="progressBarsR">
                  <div class="container progress-seting">
                     <div class="row">
                        <div class="col-md-3 col-sm-6">
                           <div class="progress blue">
                              <span class="progress-left">
                              <span class="progress-bar"></span>
                              </span>
                              <span class="progress-right">
                              <span class="progress-bar"></span>
                              </span>
                              <div class="progress-value" value="10%">100%</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <h3 data-v-0dabd3fc="">₹{{ userdetails.userAmount }}</h3>
                  <span data-v-0dabd3fc="">3rd party wallet</span>
               </div>
            </div>
            <div data-v-0dabd3fc="" class="recycleBtnD"><button data-v-0dabd3fc="" class="recycleBtn" @click="proceed()">Main wallet transfer</button></div>
            <div data-v-0dabd3fc="" class="userDetail">
               <div data-v-0dabd3fc="">
                  <div data-v-0dabd3fc="" class="imgD"><img data-v-0dabd3fc="" @click="$router.push('Recharge')" src="../assets/png/rechargeIcon-e515aee4.png"></div>
                  <span data-v-0dabd3fc="">Deposit</span>
               </div>
               <div data-v-0dabd3fc="" @click="$router.push('Withdrawal')">
                  <div data-v-0dabd3fc="" class="imgD"><img data-v-0dabd3fc="" src="../assets/png/widthdrawBlue-80197e64.png"></div>
                  <span data-v-0dabd3fc="">Withdraw</span>
               </div>
               <div data-v-0dabd3fc="">
                  <div data-v-0dabd3fc="" class="imgD"><img data-v-0dabd3fc="" @click="$router.push('RechargeHistory')" src="../assets/png/rechargeHistory-b5a853c0.png"></div>
                  <span data-v-0dabd3fc="">Deposit history</span>
               </div>
               <div data-v-0dabd3fc="" @click="$router.push('WithdrawHistory')">
                  <div data-v-0dabd3fc="" class="imgD"><img data-v-0dabd3fc="" src="../assets/png/withdrawHistory-fb2bafcf.png"></div>
                  <span data-v-0dabd3fc="">Withdrawal history</span>
               </div>
            </div>
         </div>
         <div data-v-0dabd3fc="" class="gameList">
            <div data-v-0dabd3fc="" class="box select">
               <svg data-v-0dabd3fc="" class="svg-icon icon-Lottery">
                  <use xlink:href="#icon-Lottery"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> {{ userdetails.userBalance }}</h3>
               <span data-v-0dabd3fc="">Lottery</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-EVO_Video">
                  <use xlink:href="#icon-EVO_Video"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">EVO_Video</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-JILI">
                  <use xlink:href="#icon-JILI"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">JILI</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-SEXY_Video">
                  <use xlink:href="#icon-SEXY_Video"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">S***_Video</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-TB_Chess">
                  <use xlink:href="#icon-TB_Chess"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0</h3>
               <span data-v-0dabd3fc="">TB_Chess</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-PG">
                  <use xlink:href="#icon-PG"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">PG</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-Wickets9">
                  <use xlink:href="#icon-Wickets9"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">Wickets9</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-JDB">
                  <use xlink:href="#icon-JDB"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">JDB</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-DG">
                  <use xlink:href="#icon-DG"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">DG</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-CMD">
                  <use xlink:href="#icon-CMD"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">CMD</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-CQ9">
                  <use xlink:href="#icon-CQ9"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">CQ9</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-MG">
                  <use xlink:href="#icon-MG"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">MG</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-SaBa">
                  <use xlink:href="#icon-SaBa"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">SaBa</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-TB">
                  <use xlink:href="#icon-TB"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">TB</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-AG_Video">
                  <use xlink:href="#icon-AG_Video"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">AG_Video</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-WM_Video">
                  <use xlink:href="#icon-WM_Video"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">WM_Video</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-Card365">
                  <use xlink:href="#icon-Card365"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">Card365</span>
            </div>
            <div data-v-0dabd3fc="" class="box">
               <svg data-v-0dabd3fc="" class="svg-icon icon-V8Card">
                  <use xlink:href="#icon-V8Card"></use>
               </svg>
               <h3 data-v-0dabd3fc="" class="money"> 0.00</h3>
               <span data-v-0dabd3fc="">V8Card</span>
            </div>
         </div>
      </div>
   </div>
   <!-- footer-nav -->
   <div class="footer-nav" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
      <div class="footer-nav-item" @click="$router.push('home')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-home">
            <use xlink:href="#icon-home"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Home</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('activity')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-activity">
            <use xlink:href="#icon-activity"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Activity</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('promotion')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-promotion">
            <use xlink:href="#icon-promotion"></use>
         </svg>
         <div data-v-6ab3f23e="" class="promotionBg"></div>
         <span data-v-6ab3f23e="">Promotion</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item active" @click="$router.push('wallet')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-wallet">
            <use xlink:href="#icon-wallet"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Wallet</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('Mine')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-main">
            <use xlink:href="#icon-main"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Account</span>
      </div>
   </div>
   
</template>
<script>
import axios from 'axios';
   export default {
       name: "WalletView",
       data() {
        return {
           userdetails: {
              username: null,
              balance: null,
              usercode: null,
              userWidthdraw:null,
              userAmount: 0
          },
        }
       },
       beforeCreate: function () {
       if (localStorage.getItem('username') == null)
           this.$router.push('Login');
        },
        created: function () {
           this.getUserdetails();
        },
        methods: {
           pop(message) {
           document.getElementById("snackbar").innerHTML = message;
           document.getElementById("snackbar").style.display = "";
           setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1000);
        },
        proceed() {
              this.username = localStorage.getItem('username');
              var data = new FormData();
                data.append("username", this.username);
              axios
                    .post(
                        "https://manager.jackpotclub.pro/trova/src/api/user_api_tcgames.php?transfer",
                        data
                    )
                    .then(res => {
                        if (res.data.error==true) {
                            console.log("Success", res.data.message);
                            this.pop(res.data.message);
                            this.getUserdetails();
                        } else {
                            
                            console.log("Error", res.data);
                            this.pop(res.data.message);
                            this.getUserdetails();
                        }
                    })
                    .catch(err => {
                        console.log("Error", err);
                    });
           },
        getUserdetails() {
           this.userdetails.username = localStorage.getItem('username');
           axios.get('https://manager.jackpotclub.pro/trova/src/api/wallet_api_tcgames.php?action=wallet_record2&username=' + this.userdetails.username).then((res) => {
                 this.Wallet = res.data.user_Data;
                 console.log(this.Wallet);
                 this.userdetails.userBalance = this.Wallet[0].balance;
                 
                 
           }).catch((err) => {
                 console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/wallet_api_tcgames.php?action=wallet_record3&username=' + this.userdetails.username).then((res) => {
                 this.Wallet2 = res.data.user_Data;
                 console.log(this.Wallet2);
                 this.userdetails.userAmount = this.Wallet2[0].amount;
                 
                 
           }).catch((err) => {
                 console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/wallet_api_tcgames.php?action=total_depposit&username=' + this.userdetails.username).then((res) => {
                 this.Deposit = res.data.user_Data;
                 console.log(this.Deposit);
                 this.userdetails.userDeposit = this.Deposit[0].total;
                 
                 
           }).catch((err) => {
                 console.log(err);
           })
           axios.get('https://manager.jackpotclub.pro/trova/src/api/wallet_api_tcgames.php?action=total_widthrawal&username=' + this.userdetails.username).then((res) => {
                 this.Withdrawal = res.data.user_Data;
                 console.log(this.Withdrawal);
                 this.userdetails.userWidthdraw = this.Withdrawal[0].total;
                 
                 
           }).catch((err) => {
                 console.log(err);
           })
        },
        }
   }
   
</script>